<template lang="pug">
  v-main
    router-view( :key="$router.path" )
    //- dashboard-core-footer
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
      //DashboardCoreFooter: () => import('./Footer')
    }
  }
</script>
